<template>
    <div class="w-full flex flex-wrap h-full ">

		<div class="w-2/5">
            <div class="object-cover w-full min-h-full hidden md:block bg-yellow-400">
			</div>
        </div>

        <div class="w-full md:w-3/5 flex flex-col">
			<div class="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-44 lg:px-44">

				<img src="@/assets/images/register.svg" class="w-36 mx-auto md:mt-20 mt-14" />

				<div>
					<h2 class="mt-6 text-left text-3xl font-extrabold text-gray-900">
						Register
					</h2>
					<p class="mt-2 text-left text-sm text-gray-600">
						Bergabung bersama E-learning Gamifikasi untuk memperoleh beragam materi dan tryout seputaran penerimaan ASN.
					</p>
				</div>

				<loading-modal :isShow="isLoading" />


				<form class="mt-8 space-y-8 mb-6" @submit="submitRegister()">
					<custom-input id="nama_lengkap" type="text" label="Nama lengkap" v-model="mainForm.nama_lengkap" :errorMessage="errorForm.nama_lengkap"/>
					<custom-input id="email" type="email" label="Email" v-model="mainForm.email" :errorMessage="errorForm.email"/>
					<custom-input id="wa" type="text" label="Nomor Whatsapp" v-model="mainForm.wa" :errorMessage="errorForm.wa"/>
					<custom-input id="password" type="password" label="Password" v-model="mainForm.password" :errorMessage="errorForm.password"/>
					<custom-input id="konfirmasi_password" type="password" label="Konfirmasi Password" v-model="mainForm.konfirmasi_password" :errorMessage="errorForm.konfirmasi_password"/>

					<div class="flex items-center justify-between">
						<div class="text-sm">
							Sudah memiliki akun?
							<router-link :to="{ name  : 'login'}" class="font-medium text-blue-600 hover:text-blue-500">
								Login
							</router-link>
						</div>
					</div>

					<div>
						<button type="submit"
						@click="submitRegister()" 
						:disabled="isLoading"
						class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-lg font-medium rounded-md text-blue-800 bg-blue-300 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
							<span class="absolute left-0 inset-y-0 flex items-center pl-3">
								<svg class="h-5 w-5 text-blue-500 group-hover:text-blue-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
								</svg>
							</span>
							Register
						</button>
					</div>
				</form>
			</div>
		</div>
    </div>
</template>
<script>
	import CustomInput from '../components/CustomInput.vue';
	import {showSuccessNotif, showErrorNotif, extractValidationError, errorHandler} from '../helpers/Tools';
	import LoadingModal from '../components/LoadingModal.vue';

    export default {
		name: 'Register',
		components : {
			CustomInput, LoadingModal
		},
		data () {
            return {
				isLoading : false,
                mainForm: {
                    email              : null,
                    password           : null,
                    konfirmasi_password: null,
                    nama_lengkap       : null,
                    wa                 : null,
                },
                errorForm : {
                    email              : null,
                    password           : null,
                    konfirmasi_password: null,
                    nama_lengkap       : null,
                    wa                 : null
                },
            }
        },
        mounted() {
		},
		methods : {
			async submitRegister () {
                this.isLoading = true;

                try {
                    this.errorForm = {
                        email              : null,
						password           : null,
                    	konfirmasi_password: null,
                    	nama_lengkap       : null
					};
					
					let res = await this.$store.dispatch("postRegister", this.mainForm);
					let result = res.data;
					this.isLoading = false;

					if(result.status == 'success') {
						showSuccessNotif(this, result.message);
					} else {
                        this.errorForm = extractValidationError(this.errorForm, result.data);
					}

                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },
        }
    }
</script>
